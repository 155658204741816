export const useAlternateLangs = (page) => {
  const allowedLangs = ["en", "de", "da", "x-default"];
  return (allAlternateLangs[page] || [])
  // .filter(lang => allowedLangs.includes(lang.hreflang));
};

export const allAlternateLangs = {
  "/en/google-analytics-consultant": [
    {
      hreflang: "en",
      href: "/en/google-analytics-consultant"
    },
    {
      hreflang: "de",
      href: "/de/google-analytics-consultant"
    },
    {
      hreflang: "da",
      href: "/da/google-analytics-konsulent"
    },
    {
      hreflang: "fi",
      href: "/fi/google-analytics-konsultti"
    },
    {
      hreflang: "fr",
      href: "/fr/consultant-google-analytics"
    },
    {
      hreflang: "nl",
      href: "/nl/google-analytics-consultant"
    },
    {
      hreflang: "no",
      href: "/no/google-analytics-konsulent"
    },
    {
      hreflang: "se",
      href: "/se/google-analytics-konsult"
    },
    {
      hreflang: "x-default",
      href: "/en/google-analytics-consultant"
    }
  ],
  "/en/google-analytics-freelancer": [
    {
      hreflang: "en",
      href: "/en/google-analytics-freelancer"
    },
    {
      hreflang: "de",
      href: "/de/google-analytics-freelancer"
    },
    {
      hreflang: "da",
      href: "/da/google-analytics-freelancer"
    },
    {
      hreflang: "fi",
      href: "/fi/google-analytics-freelancerina"
    },
    {
      hreflang: "fr",
      href: "/fr/google-analytics-freelance"
    },
    {
      hreflang: "nl",
      href: "/nl/google-analytics-freelancer"
    },
    {
      hreflang: "no",
      href: "/no/google-analytics-frilanser"
    },
    {
      hreflang: "se",
      href: "/se/google-analytics-frilansare"
    },
    {
      hreflang: "x-default",
      href: "/en/google-analytics-freelancer"
    }
  ],
  "/en/google-analytics-specialist": [
    {
      hreflang: "en",
      href: "/en/google-analytics-specialist"
    },
    {
      hreflang: "de",
      href: "/de/google-analytics-spezialist"
    },
    {
      hreflang: "da",
      href: "/da/google-analytics-specialist"
    },
    {
      hreflang: "fi",
      href: "/fi/google-analytics-asiantuntija"
    },
    {
      hreflang: "fr",
      href: "/fr/specialiste-google-analytics"
    },
    {
      hreflang: "nl",
      href: "/nl/google-analytics-specialist"
    },
    {
      hreflang: "no",
      href: "/no/google-analytics-spesialist"
    },
    {
      hreflang: "se",
      href: "/se/google-analytics-specialist"
    },
    {
      hreflang: "x-default",
      href: "/en/google-analytics-specialist"
    }
  ],
  "/en/google-tag-manager-consultant": [
    {
      hreflang: "en",
      href: "/en/google-tag-manager-consultant"
    },
    {
      hreflang: "de",
      href: "/de/google-tag-manager-consultant"
    },
    {
      hreflang: "da",
      href: "/da/google-tag-manager-konsulent"
    },
    {
      hreflang: "fi",
      href: "/fi/google-tag-manager-konsultti"
    },
    {
      hreflang: "fr",
      href: "/fr/consultant-google-tag-manager"
    },
    {
      hreflang: "nl",
      href: "/nl/google-tag-manager-consultant"
    },
    {
      hreflang: "no",
      href: "/no/google-tag-manager-konsulent"
    },
    {
      hreflang: "se",
      href: "/se/google-tag-manager-konsult"
    },
    {
      hreflang: "x-default",
      href: "/en/google-tag-manager-consultant"
    }
  ],
  "/en/seo-freelancer": [
    {
      hreflang: "en",
      href: "/en/seo-freelancer"
    },
    {
      hreflang: "de",
      href: "/de/seo-freelancer"
    },
    {
      hreflang: "da",
      href: "/da/seo-freelancer"
    },
    {
      hreflang: "fi",
      href: "/fi/seo-freelancerina"
    },
    {
      hreflang: "fr",
      href: "/fr/seo-freelance"
    },
    {
      hreflang: "nl",
      href: "/nl/seo-freelancer"
    },
    {
      hreflang: "no",
      href: "/no/seo-frilanser"
    },
    {
      hreflang: "se",
      href: "/se/seo-frilansare"
    },
    {
      hreflang: "x-default",
      href: "/en/seo-freelancer"
    }
  ],
  "/en/google-tag-manager-setup": [
    {
      hreflang: "en",
      href: "/en/google-tag-manager-setup"
    },
    {
      hreflang: "de",
      href: "/de/google-tag-manager-einrichten"
    },
    {
      hreflang: "da",
      href: "/da/google-tag-manager-opsætning"
    },
    {
      hreflang: "fi",
      href: "/fi/google-tag-manager-asennus"
    },
    {
      hreflang: "fr",
      href: "/fr/configuration-de-google-tag-manager"
    },
    {
      hreflang: "nl",
      href: "/nl/google-tag-manager-setup"
    },
    {
      hreflang: "no",
      href: "/no/google-tag-manager-oppsett"
    },
    {
      hreflang: "se",
      href: "/se/google-tag-manager-setup"
    },
    {
      hreflang: "x-default",
      href: "/en/google-tag-manager-setup"
    }
  ],
  "/en/about": [
    {
      hreflang: "da",
      href: "/da/om-mig"
    },
    {
      hreflang: "de",
      href: "/de/ueber-mich"
    },
    {
      hreflang: "fi",
      href: "/fi/tietoa"
    },
    {
      hreflang: "fr",
      href: "/fr/à-propos"
    },
    {
      hreflang: "nl",
      href: "/nl/over"
    },
    {
      hreflang: "no",
      href: "/no/om"
    },
    {
      hreflang: "se",
      href: "/se/om"
    },
    {
      hreflang: "en",
      href: "/en/about"
    },
    {
      hreflang: "x-default",
      href: "/en/about"
    }
  ],
  "/en/analytics": [
    {
      hreflang: "da",
      href: "/da/analytics"
    },
    {
      hreflang: "de",
      href: "/de/analytics"
    },
    {
      hreflang: "fi",
      href: "/fi/analytiikka"
    },
    {
      hreflang: "fr",
      href: "/fr/analytique"
    },
    {
      hreflang: "nl",
      href: "/nl/analytics"
    },
    {
      hreflang: "no",
      href: "/no/analytics"
    },
    {
      hreflang: "se",
      href: "/se/analys"
    },
    {
      hreflang: "en",
      href: "/en/analytics"
    },
    {
      hreflang: "x-default",
      href: "/en/analytics"
    }
  ],
  "/en/analytics-consulting": [
    {
      hreflang: "da",
      href: "/da/analyse-konsulent"
    },
    {
      hreflang: "de",
      href: "/de/google-analytics-beratung"
    },
    {
      hreflang: "fi",
      href: "/fi/analytiikka-konsultointi"
    },
    {
      hreflang: "fr",
      href: "/fr/consultation-analytique"
    },
    {
      hreflang: "nl",
      href: "/nl/analytics-consulting"
    },
    {
      hreflang: "no",
      href: "/no/analyse-rådgivning"
    },
    {
      hreflang: "se",
      href: "/se/analysrådgivning"
    },
    {
      hreflang: "en",
      href: "/en/analytics-consulting"
    },
    {
      hreflang: "x-default",
      href: "/en/analytics-consulting"
    }
  ],
  "/en/contact": [
    {
      hreflang: "da",
      href: "/da/kontakt"
    },
    {
      hreflang: "de",
      href: "/de/kontakt"
    },
    {
      hreflang: "fi",
      href: "/fi/yhteydenotto"
    },
    {
      hreflang: "fr",
      href: "/fr/le-contact"
    },
    {
      hreflang: "nl",
      href: "/nl/contact"
    },
    {
      hreflang: "no",
      href: "/no/contact"
    },
    {
      hreflang: "se",
      href: "/se/kontakt"
    },
    {
      hreflang: "en",
      href: "/en/contact"
    },
    {
      hreflang: "x-default",
      href: "/en/contact"
    }
  ],
  "/en/data-layer": [
    {
      hreflang: "da",
      href: "/da/data-lag"
    },
    {
      hreflang: "de",
      href: "/de/data-layer"
    },
    {
      hreflang: "fi",
      href: "/fi/data-kerros"
    },
    {
      hreflang: "fr",
      href: "/fr/couche-de-données"
    },
    {
      hreflang: "nl",
      href: "/nl/data-laag"
    },
    {
      hreflang: "no",
      href: "/no/data-lag"
    },
    {
      hreflang: "se",
      href: "/se/data-lager"
    },
    {
      hreflang: "en",
      href: "/en/data-layer"
    },
    {
      hreflang: "x-default",
      href: "/en/data-layer"
    }
  ],
  "/en/event-tracking": [
    {
      hreflang: "da",
      href: "/da/event-sporing"
    },
    {
      hreflang: "de",
      href: "/de/event-tracking"
    },
    {
      hreflang: "fi",
      href: "/fi/tapahtuman-seuranta"
    },
    {
      hreflang: "fr",
      href: "/fr/suivi-des-événements"
    },
    {
      hreflang: "nl",
      href: "/nl/event-tracking"
    },
    {
      hreflang: "no",
      href: "/no/hendelsessporing"
    },
    {
      hreflang: "se",
      href: "/se/händelsespårning"
    },
    {
      hreflang: "en",
      href: "/en/event-tracking"
    },
    {
      hreflang: "x-default",
      href: "/en/event-tracking"
    }
  ],
  "/en/google-analytics-setup": [
    {
      hreflang: "da",
      href: "/da/google-analytics-opsætning"
    },
    {
      hreflang: "de",
      href: "/de/google-analytics-einrichten"
    },
    {
      hreflang: "fi",
      href: "/fi/google-analytics-asennus"
    },
    {
      hreflang: "fr",
      href: "/fr/configuration-de-google-analytics"
    },
    {
      hreflang: "nl",
      href: "/nl/google-analytics-installatie"
    },
    {
      hreflang: "no",
      href: "/no/google-analytics-oppsett"
    },
    {
      hreflang: "se",
      href: "/se/google-analytics-setup"
    },
    {
      hreflang: "en",
      href: "/en/google-analytics-setup"
    },
    {
      hreflang: "x-default",
      href: "/en/google-analytics-setup"
    }
  ],
  "/en/imprint": [
    {
      hreflang: "da",
      href: "/da/juridisk-information"
    },
    {
      hreflang: "de",
      href: "/de/impressum"
    },
    {
      hreflang: "fi",
      href: "/fi/imprint"
    },
    {
      hreflang: "fr",
      href: "/fr/imprint"
    },
    {
      hreflang: "nl",
      href: "/nl/impressum"
    },
    {
      hreflang: "no",
      href: "/no/imprint"
    },
    {
      hreflang: "se",
      href: "/se/juridisk-information"
    },
    {
      hreflang: "en",
      href: "/en/imprint"
    },
    {
      hreflang: "x-default",
      href: "/en/imprint"
    }
  ],
  "/en": [
    {
      hreflang: "da",
      href: "/da"
    },
    {
      hreflang: "de",
      href: "/de"
    },
    {
      hreflang: "fi",
      href: "/fi"
    },
    {
      hreflang: "fr",
      href: "/fr"
    },
    {
      hreflang: "nl",
      href: "/nl"
    },
    {
      hreflang: "no",
      href: "/no"
    },
    {
      hreflang: "se",
      href: "/se"
    },
    {
      hreflang: "en",
      href: "/en"
    },
    {
      hreflang: "x-default",
      href: "/en"
    }
  ],
  "/en/legal-notice": [
    {
      hreflang: "da",
      href: "/da/juridisk-meddelelse"
    },
    {
      hreflang: "de",
      href: "/de/allgemeine-geschaeftsbedingungen"
    },
    {
      hreflang: "fi",
      href: "/fi/oikeudellinen-ilmoitus"
    },
    {
      hreflang: "fr",
      href: "/fr/mention-légale"
    },
    {
      hreflang: "nl",
      href: "/nl/juridische-mededeling"
    },
    {
      hreflang: "no",
      href: "/no/juridisk-merknad"
    },
    {
      hreflang: "se",
      href: "/se/juridiskt-meddelande"
    },
    {
      hreflang: "en",
      href: "/en/legal-notice"
    },
    {
      hreflang: "x-default",
      href: "/en/legal-notice"
    }
  ],
  "/en/privacy-policy": [
    {
      hreflang: "da",
      href: "/da/privatlivspolitik"
    },
    {
      hreflang: "de",
      href: "/de/datenschutz"
    },
    {
      hreflang: "fi",
      href: "/fi/tietosuojakäytäntö"
    },
    {
      hreflang: "fr",
      href: "/fr/politique-de-confidentialité"
    },
    {
      hreflang: "nl",
      href: "/nl/privacybeleid"
    },
    {
      hreflang: "no",
      href: "/no/privacy-policy"
    },
    {
      hreflang: "se",
      href: "/se/integritetspolicy"
    },
    {
      hreflang: "en",
      href: "/en/privacy-policy"
    },
    {
      hreflang: "x-default",
      href: "/en/privacy-policy"
    }
  ],
  "/en/seo": [
    {
      hreflang: "da",
      href: "/da/seo"
    },
    {
      hreflang: "de",
      href: "/de/seo"
    },
    {
      hreflang: "fi",
      href: "/fi/seo"
    },
    {
      hreflang: "fr",
      href: "/fr/seo"
    },
    {
      hreflang: "nl",
      href: "/nl/seo"
    },
    {
      hreflang: "no",
      href: "/no/seo"
    },
    {
      hreflang: "se",
      href: "/se/seo"
    },
    {
      hreflang: "en",
      href: "/en/seo"
    },
    {
      hreflang: "x-default",
      href: "/en/seo"
    }
  ],
  "/en/services": [
    {
      hreflang: "da",
      href: "/da/services"
    },
    {
      hreflang: "de",
      href: "/de/leistungen"
    },
    {
      hreflang: "fi",
      href: "/fi/palvelut"
    },
    {
      hreflang: "fr",
      href: "/fr/les-services"
    },
    {
      hreflang: "nl",
      href: "/nl/diensten"
    },
    {
      hreflang: "no",
      href: "/no/tjenester"
    },
    {
      hreflang: "se",
      href: "/se/tjänster"
    },
    {
      hreflang: "en",
      href: "/en/services"
    },
    {
      hreflang: "x-default",
      href: "/en/services"
    }
  ],
  "/en/tag-management": [
    {
      hreflang: "da",
      href: "/da/tag-styring"
    },
    {
      hreflang: "de",
      href: "/de/tag-management"
    },
    {
      hreflang: "fi",
      href: "/fi/tagien-hallinta"
    },
    {
      hreflang: "fr",
      href: "/fr/gestion-des-tags"
    },
    {
      hreflang: "nl",
      href: "/nl/tagbeheer"
    },
    {
      hreflang: "no",
      href: "/no/tagg-administrasjon"
    },
    {
      hreflang: "se",
      href: "/se/tagghantering"
    },
    {
      hreflang: "en",
      href: "/en/tag-management"
    },
    {
      hreflang: "x-default",
      href: "/en/tag-management"
    }
  ],
  "/en/blog": [{
    hreflang: "en",
    href: "/en/blog"
  },
  {
    hreflang: "de",
    href: "/de/blog"
  },
  {
    hreflang: "da",
    href: "/da/blog"
  },
  {
    hreflang: "se",
    href: "/se/blogg"
  },
  {
    hreflang: "no",
    href: "/no/blogg"
  },
  {
    hreflang: "nl",
    href: "/nl/blog"
  },
  {
    hreflang: "x-default",
    href: "/en/blog"
  }],
  "/en/analytics-wiki": [{
    hreflang: "se",
    href: "/se/analys-wiki"
  },
  {
    hreflang: "da",
    href: "/da/analytics-wiki"
  },
  {
    hreflang: "no",
    href: "/no/analyse-wiki"
  },
  {
    hreflang: "nl",
    href: "/nl/analytics-wiki"
  },
  {
    hreflang: "en",
    href: "/en/analytics-wiki"
  },
  {
    hreflang: "de",
    href: "/de/analytics-wiki"
  },
  {
    hreflang: "x-default",
    href: "/en/analytics-wiki"
  }],
  "/en/analytics": [
    {
      hreflang: "en",
      href: "/en/analytics"
    },
    {
      hreflang: "de",
      href: "/de/analytics"
    },
    {
      hreflang: "da",
      href: "/da/analytics"
    },
    {
      hreflang: "se",
      href: "/se/analys"
    },
    {
      hreflang: "no",
      href: "/no/analytics"
    },
    {
      hreflang: "nl",
      href: "/nl/analytics"
    },
    {
      hreflang: "x-default",
      href: "/en/analytics"
    }
  ],
  "/en/faq": [
    {
      hreflang: "de",
      href: "/de/faq"
    },
    {
      hreflang: "en",
      href: "/en/faq"
    },
    {
      hreflang: "se",
      href: "/se/faq"
    },
    {
      hreflang: "da",
      href: "/da/faq"
    },
    {
      hreflang: "no",
      href: "/no/faq"
    },
    {
      hreflang: "nl",
      href: "/nl/faq"
    },
    {
      hreflang: "x-default",
      href: "/en/faq"
    }
  ]
};


