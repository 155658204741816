import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import MainFrontpage from "../components/MainFrontpage";
import MainLeftColumn from "../components/MainLeftColumn";
import MainRightColumn from "../components/MainRightColumn";
import FormContext from "../components/FormContext";
import { useAlternateLangs } from "../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en"
);


const Index = ({ location }) => (

  <FormContext>
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Blue River Mountains | Google Analytics & SEO Consulting"
          lang="en"
          description="Consultancy for SEO & Web Analytics on a freelance basis. Learn about SEO or Google Analytics in our tutorials or contact us for help with more advanced problems."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/en"
        />
        <MainFrontpage >
          <MainLeftColumn />
          <MainRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  </FormContext>

);

export default React.memo(Index);
